import banner from '../images/hero_banner.jpg'
import bannerM from '../images/hero_bannerM.jpg'
import styled from 'styled-components'

const StyledHero = styled.header`
  width: 100%;
`

function Header() {
  return (
    <StyledHero id="header">
      <img className='d-none d-md-block' width="100%" src={banner} alt="Hero Visual" />
      <img className='d-block d-md-none' width="100%" src={bannerM} alt="Hero Visual" />
    </StyledHero>
  );
}

export default Header;
