import { Navbar, Nav, Container } from 'react-bootstrap';
import styled from 'styled-components'
import useAnalyticsEventTracker from '../utils/useAnalyticsEventTracker';

const StyledNav = styled(Navbar)`
  background: transparent;
  width: 100%;
  padding: 17px 0;

  .nav-link {
    color: #65362C;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px !important;
    padding: 8px 24px !important;
  }
  .active {
    color: #b98e5e !important;
  }
`

function NavigationBar() {
  const gaEventTracker = useAnalyticsEventTracker('Navigation')

  return (
    <StyledNav expand="lg" id="home">
      <Container>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className='ms-auto' />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link onClick={() => gaEventTracker('Click Message')} href="#message">MESSAGE FROM EDITOR-IN-CHIEF</Nav.Link>
            <Nav.Link onClick={() => gaEventTracker('Click Recipes')} href="#recipes">RECIPES FROM SEA</Nav.Link>
            <Nav.Link onClick={() => gaEventTracker('Click Acknowledgement')} href="#acknowledgement">ACKNOWLEDGEMENT</Nav.Link>
            <Nav.Link onClick={() => gaEventTracker('Click E-book')} href="#download">OUR E-BOOK</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </StyledNav>
  );
}

export default NavigationBar;
