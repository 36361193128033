import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  .modal-content {
    background: transparent;
    border: none;
    border-radius: 12px;
  }
  .modal-body {
    padding: 0rem;
    text-align: center;
  }
  .modal-header {
    padding-top: 0;
    padding-right: 8px;
    border: none;
    button.btn-close {
      padding: 0;
      font-size: 28px;
      opacity: 1;
      transition: all 400ms;
      filter: invert(1);
      &:hover {
        filter: invert(0);
      }
    }
  }
  .iframe-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
    }
    h4 {
      color: #fff;
      font-weight: 800;
      letter-spacing: 2px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`

export default function EBookModal({ showModal, hideModal }) {
  const [loading, setLoading] = useState(true)

  return (
    <StyledModal show={showModal} onHide={hideModal} size="xl" aria-labelledby="e-book embed" centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="iframe-container" >
          {loading && <h4>Loading . . .</h4>}
          <iframe
            width="100%"
            height="100%"
            title="E-book Embed"
            src="https://online.anyflip.com/vqznq/hkqr/index.html"   
            allow="autoplay; fullscreen; encrypted-media"
            allowFullScreen
            allowtransparency="true"
            frameBorder="0"
            onLoad={() => {
              setLoading(false)
            }}
          />
        </div>
      </Modal.Body>
    </StyledModal>
  )
}
