import bg from '../images/sky_bg.png'
import bgM from '../images/sky_bgM.png'
import styled from 'styled-components'
import { Container } from 'react-bootstrap';

const StyledMessage = styled.section`
  width: 100%;
  min-height: 100%;
  position: relative;
  color: #65362C;
  .container {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -30%);
    width: 60%;
  }
  .message-text {
    text-align: center;
    font-size: 20px;
  }
  @media (max-width: 768px) {
    padding: 5rem 0;
    background-image: url(${bgM});
    background-size: cover;
    background-position: bottom center;

    .container {
      position: relative;
      transform: initial;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1;
    }
    .bg-layer {
      position: absolute;
      top: 0;
      left: 0;
    }
    .message-text {
      font-size: 16px;
      padding: 0 18px;
    }
  }
`

function Message() {
  return (
    <StyledMessage id="message">
      <img className='bg-layer d-none d-md-block' width="100%" src={bg} alt="Hero Visual" />

      <Container>
        <h2 className='title'>Message from Editor-in-Chief</h2>
        <br />
        <p className='message-text'>The series of cookbook highlights the use of oats in local cuisines as an effort to increase whole grain intake. The recipes in these cookbooks have been selected from the diverse variety of traditional dishes and cuisines from the respective countries of the five professional bodies and institutions. Oats are incorporated into the dishes either by adding or replacing other ingredients so that the taste of these dishes can be easily accepted by the population. Through these dishes, it can be seen that oats is not just for the sick and elderly.</p>
        <br />
        <p className='message-text'>With the publication of the series of cookbook, we hope more people will gain understanding on the benefits of whole grains and discover the many ways to include more of this wholesome food in the diet. Hopefully, this cookbook, developed with an educational grant from the Pepsico Services Asia Ltd (Quaker), will inspire more to enjoy cooking and learn that healthy cooking is actually not difficult. Have fun trying out these oat-based recipes!</p>
      </Container>
    </StyledMessage>
  );
}

export default Message;
