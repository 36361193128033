import book from '../images/book.png'
import oats from '../images/oats.png'
import styled from 'styled-components'
import useAnalyticsEventTracker from '../utils/useAnalyticsEventTracker'

const StyledDownload = styled.header`
  text-align: center;
  color: #65362C;
  padding: 0;
  position: relative;

  .message-text {
    text-align: center;
    font-size: 20px;
  }
  #oats-layer {
    position: absolute;
    top: 0;
    left: 50%;
    width: 90%;
    filter: contrast(1.1);
    z-index: 10;
    animation: float 10s infinite;
  }
  #front {
    z-index: 100;
    margin-top: 5rem;
    position: relative;
  }
  #book-layer {
    margin-top: -5%;
  }
  #view-btn {
    background: #F09180;
    &:hover {
      background: #5F382E;
      color: #ffffff;
    }
  }
  #download-btn {
    background: #e3bb8b;
    &:hover {
      background: #5F382E;
      color: #ffffff;
    }
  }
  .book-buttons {
    color: #000;
    border: none;
    border-radius: 10px;
    font-weight: bold;
    font-size: 24px;
    padding: 6px 28px;
    position: relative;
    z-index: 1;
    margin: 16px;
    transition: all 500ms;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }
 
  #back-top {
    position: absolute;
    z-index: 100;
    text-align: center;
    color: #65362C !important;
    bottom: 3rem;
    left: 50%;
    transform: translateX(-50%);
    transition: all 200ms;
    &:hover, &:visited {
      color: #E2786A;
    }
  }
  @media (max-width: 768px) {
    #oats-layer {
      width: 150%;
      transform: translate(-50%, -50%);
    }
    .message-text {
      font-size: 16px;
      padding: 0 18px;
    }
    #back-top {
      bottom: 0.5rem;
    }
  }
`

function Book({ clickDownload, clickView }) {
  const gaEventTracker = useAnalyticsEventTracker('Navigation')

  return (
    <StyledDownload id="download">
      <img id="oats-layer" src={oats} alt="Oats Layer" />
      <div id='front'>
        <h2 className='title'>Our E-book</h2>
        <br />
        <p className='message-text'>Have fun trying out these oat-based recipes!</p>
        <button className='book-buttons' id="view-btn" onClick={clickView}>View E-Book</button>
        <button className='book-buttons' id="download-btn" onClick={clickDownload}>Download E-Book</button>
        <img id="book-layer" src={book} width="100%" alt="Book Visual" />
      </div>
      <a onClick={() => gaEventTracker('Back to Top')} href="#home" id="back-top">Back to Top</a>
    </StyledDownload>
  );
}

export default Book;
