import countries from '../images/countries.png'
import countriesM from '../images/countriesM.png'
import styled from 'styled-components'
import { Container } from 'react-bootstrap';

const StyledRecipe = styled.section`
  color: #65362C;
  padding: 5rem 0;

  .container {
    width: 80%;
  }
  .message-text {
    text-align: center;
    font-size: 20px;
  }
  @media (max-width: 768px) {
    padding: 5rem 0;

    .container {
      width: 90%;
    }
    
    .message-text {
      font-size: 16px;
      padding: 0 18px;
    }
  }
`

function Countries() {
  return (
    <StyledRecipe id="recipes">
      <Container>
        <h2 className='title'>Recipes from Southeast Asia</h2>
        <br />
        <p className='message-text'>50 Recipes from Indonesia, Malaysia, Philippines, Thailand & Vietnam</p>
        <img className='bg-layer d-none d-md-block' width="100%" src={countries} alt="Hero Visual" />
        <img className='bg-layer d-block d-md-none' width="100%" src={countriesM} alt="Hero Visual" />
      </Container>
    </StyledRecipe>
  );
}

export default Countries;
