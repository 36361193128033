import { useEffect, useState } from "react";
import Acknowledgement from "./components/Acknowledgement";
import Book from "./components/Book";
import EBookModal from "./components/EBookModal";
import Countries from "./components/Countries";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Message from "./components/Message";
import NavigationBar from "./components/NavigationBar";
import ReactGA from 'react-ga';
import useAnalyticsEventTracker from "./utils/useAnalyticsEventTracker";

const TRACKING_ID = "UA-180732110-6"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  const gaEventTracker = useAnalyticsEventTracker('Home Page')
  const [showModal, setShowModal] = useState(false)

  const clickView = () => {
    setShowModal(true)
    gaEventTracker('View Ebook')
  }

  const clickDownload = () => {
    window.open("/QUAKER_Recipe_E-Book.pdf", "_blank")
    gaEventTracker('Download Ebook')
  }

  const hideModal = () => {
    setShowModal(false)
  }

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      <div id="innerBody" className="fade-style">
        <NavigationBar />
        <Header />
        <Message />
        <Countries />
        <Acknowledgement />
        <Book clickDownload={clickDownload} clickView={clickView} />
        <Footer />
      </div>
      <EBookModal showModal={showModal} hideModal={hideModal} />
    </>
  );
}

export default App;
