
import styled from 'styled-components'

const StyledFooter = styled.header`
  background: #5A3A30;
  color: #FFFFFF;
  text-align: center;
  padding: 16px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  p {
    font-size: 12px;
    margin: 0;
    letter-spacing: 1px;
  }

  span {
    margin: 0 2rem;
  }
  @media (max-width: 768px) {
    p {
      font-size: 10px;
    }
    span {
      font-size: 10px;
      margin: 0 1rem;
    }
    .mobile-hide {
      display: none;
    }
  }
`

function Footer() {
  return (
    <StyledFooter>
      <p>Copyright © Nutrition Society of Malaysia 2022</p>
      <span className='mobile-hide'>|</span>
      <p>Built by CloudExpo Sdn. Bhd.</p>
      <span>|</span>
      <p>All Rights Reserved.</p>
    </StyledFooter>
  );
}

export default Footer;
