import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`

  
  body {
    font-family: 'Barlow', sans-serif;
    margin: 0;
  }

  #innerBody {
    background: #FFFFFF;
    background-size: 200% 200%;
    overflow-x: hidden;
  }

  .title {
    font-family: 'SignPainter', sans-serif;
    font-size: 75px;
    font-weight: bold;
    line-height: 88px;
    text-align: center;
    @media (max-width: 768px) {
      font-size: 32px;
      line-height: 36px;
    }
  }

  * {
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
  }

  *:focus {
    outline: none !important;
  }

  .fade-style {
    opacity: 0;
    animation: fadeIn 2s forwards;
  }
  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
  @keyframes float {
    0% {
      transform: translate(-50%, -45%);
    }
    50% {
      transform: translate(-50%, -40%);
    }
    100% {
      transform: translate(-50%, -45%);
    }
  }
  @keyframes rotating {
    0% {
      transform: rotate(0deg);
    }
    30% {
      transform: rotate(-30deg);
    }
    70% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export default GlobalStyle
