import logo1 from '../images/ack-1.png'
import logo2 from '../images/ack-2.png'
import logo3 from '../images/ack-3.png'
import logo4 from '../images/ack-4.png'
import logo5 from '../images/ack-5.png'
import logo6 from '../images/ack-6.png'
import love from '../images/love-oat.png'
import bg from '../images/acknowledgement_bg.png'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap';

const StyledSection = styled.section`
  color: #65362C;
  padding: 5rem 0;
  background-image: url(${bg});
  background-size: cover;
  background-position: bottom center;
  position: relative;
  z-index: 100;

  .container {
    width: 90%;
  }
  .subtitle {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    height: 2rem;
  }
  .logo-container {
    justify-content: space-between;
  }
  .logo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;
    &:hover {
      .publisher {
        transform: translateY(-15%);
      }
      .publisher-name {
        transform: translateY(-15%);
      }
    }
  }
  .publisher {
    display: block;
    margin: 0 auto;
    transition: all 400ms;
  }
  .publisher-name {
    color: #000000;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    margin: 1rem 0;
    padding: 0 12px;
    transition: all 200ms;
  }
  .mobile-subtitle {
    display: none;
  }
  #love {
    position: absolute;
    top: -8%;
    left: 5%;
    width: 15%;
    transition: all 300ms;
    animation: rotating 3s infinite;
  }
  
  @media (max-width: 768px) {
    padding: 5rem 0;

    .subtitle {
      display: none;
    }
    .mobile-subtitle {
      display: block;
      font-size: 16px;
      padding: 0 18px;
      text-align: center;
      font-weight: bold;
    }
    .logo-container {
      justify-content: center;
    }
    #love {
      position: absolute;
      top: -2%;
      left: 8%;
      width: 25%;
    }
  }
`

function Acknowledgement() {
  return (
    <StyledSection id="acknowledgement">
      <img id="love" src={love} alt="Love" />
      <Container>
        <h2 className='title'>Acknowledgement</h2>
        <br />
        <p className='mobile-subtitle'>Jointly published by:</p>
        <Row nogutters="true" className='logo-container'>
          <Col xs={6} md={2} className='logo'>
            <p className='subtitle'></p>
            <img className='publisher' width="75%" src={logo1} alt="Publisher Logo" />
            <p className='publisher-name'>Food and Nutrition Society of Indonesia (PERGIZI PANGAN Indonesia)</p>
          </Col>
          <Col xs={6} md={2} className='logo'>
            <p className='subtitle'></p>
            <img className='publisher' width="85%" src={logo2} alt="Publisher Logo" />
            <p className='publisher-name'>Nutrition Society of Malaysia</p>
          </Col>
          <Col xs={6} md={2} className='logo'>
            <p className='subtitle'>Jointly published by:</p>
            <img className='publisher' width="75%" src={logo3} alt="Publisher Logo" />
            <p className='publisher-name'>Nutrition Foundation of the Philippines, Inc.</p>
          </Col>
          <Col xs={6} md={2} className='logo'>
            <p className='subtitle'></p>
            <img className='publisher' width="80%" src={logo4} alt="Publisher Logo" />
            <p className='publisher-name'>Institute of Nutrition Mahidol University</p>
          </Col>
          <Col xs={6} md={2} className='logo'>
            <p className='subtitle'></p>
            <img className='publisher' width="100px" src={logo5} alt="Publisher Logo" />
            <p className='publisher-name'>Vietnam Nutrition Association (VINUTAS)</p>
          </Col>
          <Col xs={12} md={2} className='logo d-md-none'><br /></Col>
          <Col xs={6} md={2} className='logo'>
            <p className='subtitle'>Supported by:</p>
            <p className='mobile-subtitle'>Supported by:</p>
            <img className='publisher my-5' width="90%" src={logo6} alt="Publisher Logo" />
            <p className='publisher-name'>Pepsico Services Asia Ltd (Quaker)</p>
          </Col>
        </Row>
      </Container>
    </StyledSection>
  );
}

export default Acknowledgement;
